<template>
  <Layout>
    <PageHeader :title=title :items="itemHeader"></PageHeader>
    <div class="row">
      <div class="col-xxl-9 col-md-9">
        <div class="row">
          <div class="col-xxl-12 col-md-12" v-if="!loading && state.facility">
            <facility-information @reload="loadFacility" :is-my-facility="false" :facility="state.facility"
                                  :facility-id="state.facilityId.toString()"/>
          </div>

          <div class="col-xxl-12 col-md-12">
            <Room room-sub-type="SAUNA_DRIED" :is-my-facility="false" :facility="state.facility"
                  :facility-id="state.facilityId.toString()"
                  :state-update-room="state.stateUpdateRoom"
                  @stateUpdateRoom="updateStateRoom"
            />
          </div>

          <div class="col-xxl-12 col-md-12">
            <Room room-sub-type="SAUNA_WET" :is-my-facility="false" :facility="state.facility"
                  :facility-id="state.facilityId.toString()"
                  :state-update-room="state.stateUpdateRoom"
                  @stateUpdateRoom="updateStateRoom"/>
          </div>

          <div class="col-xxl-12 col-md-12">
            <Room room-sub-type="SAUNA_RESTED" :is-my-facility="false" :facility="state.facility"
                  :facility-id="state.facilityId.toString()"
                  :state-update-room="state.stateUpdateRoom"
                  @stateUpdateRoom="updateStateRoom"
            />
          </div>
        </div>
      </div>
      <div class="col-xxl-3 col-md-3">
        <div class="row">
          <div class="col-xxl-12 col-md-12" v-if="!loading && state.facility">
            <FacilityFilterInformation @reload="loadFacility" :facility="state.facility" :is-my-facility="false"
                                       :facility-id="state.facilityId.toString()"/>
          </div>

          <div class="col-xxl-12 col-md-12">
            <Settings/>
          </div>

          <div class="col-xxl-12 col-md-12">
            <SettingAds
              :facility-id="state.facilityId.toString()"
              :playlist-id="state.facility.playlistId"
              :playlist-type="state.facility.playlistType"
              :state-update-room="state.stateUpdateRoom"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import {onMounted, reactive, ref, watch} from "vue";
import {useRoute} from 'vue-router'
import {facilityService} from "@/services";
import Layout from '@/layouts/main.vue'
import PageHeader from "@/components/page-header.vue";
import i18n from "@/i18n";
import FacilityInformation from "@/views/setting/facility-management/components/FacilityInformation.vue";
import Settings from "@/views/setting/facility-management/components/Settings.vue";
import FacilityFilterInformation from "@/views/setting/facility-management/components/FacilityFilterInformation.vue";
import Room from "@/views/setting/facility-management/components/Room.vue"
import SettingAds from "@/views/setting/facility-management/components/SettingAds.vue";

export default {
  components: {
    Layout,
    PageHeader,
    FacilityInformation,
    Settings,
    FacilityFilterInformation,
    Room,
    SettingAds
  },
  methods: {
    updateStateRoom(value) {
      this.state.stateUpdateRoom = value
    }
  },
  setup() {
    const loading = ref(false);
    const title = ref(i18n.global.t('facility.facility-details'));
    const itemHeader = reactive([
      {
        text: i18n.global.t('facility.facility-information'),
        href: "/admin/facility/list",
      },
      {
        text: i18n.global.t('facility.facility-details'),
        active: true,
      },
    ]);
    const router = useRoute();
    let state = reactive({
      facility: {},
      facilityId: ref(router.params.facilityId),
      stateUpdateRoom: 0,
    })

    const loadFacility = () => {
      console.log('loadFacility')
    }


    const getFacility = async () => {
      try {
        loading.value = true;
        state.facility = await facilityService.getFacility(state.facilityId);
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    }

    onMounted(() => {
      getFacility();
    })

    return {
      itemHeader,
      title,
      loading,
      state,
      loadFacility
    }
  }
}

</script>
